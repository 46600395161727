// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Menu,
  Dropdown,
  Icon,
  Divider,
  Modal,
  Tabs, Badge,
} from "antd";
import StatusesInfo from "./StatusesInfo";
import Activities from "./Activities";
import Comments from "./Comments";
import GeneralInfo from "./GeneralInfo";
import Tasks from "../../../components/Tasks";
import moment from "moment";
import "moment/locale/ru";
import Documents from "../Documents";

const { confirm } = Modal;
const { TabPane } = Tabs;

class FormInfo extends Component {
  onResetStatus = () => {
    const { onResetStatus } = this.props;
    confirm({
      title: "Вы хотите сбросить статус и возобновить работы?",
      content:
        "Стутус по заявке будет изменен на 'приступил к выполнению' и исполнителям будет выслано уведомление о требовании возобновить работы.",
      onOk() {
        onResetStatus();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchRequestForTicketActivities();
    }

    if (key == "3") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { ticket, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], ticket.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      currentMember,
      isNew,
      isLoading,
      isCommentsLoading,
      isUploading,
      isAssigneesLoading,
      errors,
      ticket,
      onChangeNumber,
      onClose,
      onCreateComment,
      onDeleteAttachment,
      onUploadAttachment,
      onCreateAssignee,
      onDeleteAssignee,
      // statuses
      onChangeStatus,
      onChangeReplaced,
      onChangeOrdered,
      // activities
      ticketActivities,
      ticketActivityOverdues,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // settings
      settings,
      // actions
      actions,
    } = this.props;

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    const organisationAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const companyAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const customerAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const commonAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 1;
    });

    const actAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 2;
    });

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2 style={{ marginBottom: 0 }}>
                {`Заявка #${ticket.number} от ${moment(
                  ticket.created_at
                ).format("LLL")}`}
              </h2>
            </div>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Row>
              <GeneralInfo ticket={ticket} />
            </Row>

            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs defaultActiveKey="7" onChange={this.callback}>
                  <TabPane tab="Комментарии" key="7">
                    <Comments
                      getFieldDecorator={getFieldDecorator}
                      isCommentsLoading={isCommentsLoading}
                      errors={errors}
                      onCreateComment={onCreateComment}
                      comments={ticket.comments}
                    />
                  </TabPane>
                  <TabPane tab="Файлы" key="8">
                    <Row>
                      <Documents
                        currentMember={currentMember}
                        title={""}
                        isUploading={isUploading}
                        attachments={commonAttachments}
                        onUpload={onUploadAttachment}
                        onDelete={onDeleteAttachment}
                      />
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Row>
              <Card style={{ borderColor: "#1890ff" }} loading={isLoading}>
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                  <TabPane tab="Статусы" key="1">
                    <StatusesInfo
                      getFieldDecorator={getFieldDecorator}
                      currentMember={currentMember}
                      isNew={isNew}
                      ticket={ticket}
                      isSupplier={ticket.is_supplier}
                      isLoading={isLoading}
                      isUploading={isUploading}
                      onChangeNumber={onChangeNumber}
                      // documents
                      attachments={actAttachments}
                      isUploading={isUploading}
                      onDeleteAttachment={onDeleteAttachment}
                      onUploadAttachment={onUploadAttachment}
                      // assignees
                      organisationAssignees={organisationAssignees}
                      supervisorAssignees={supervisorAssignees}
                      customerAssignees={customerAssignees}
                      companyAssignees={companyAssignees}
                      isAssigneesLoading={isAssigneesLoading}
                      onDeleteAssignee={onDeleteAssignee}
                      onCreateAssignee={onCreateAssignee}
                      // statuses
                      onChangeStatus={onChangeStatus}
                      onChangeReplaced={onChangeReplaced}
                      onChangeOrdered={onChangeOrdered}
                    />
                  </TabPane>
                  {isNew || !settings.is_ticket_logs ? null : (
                    <TabPane key="2"
                             tab={<Badge style={{ backgroundColor: '#ff6600'}}
                                         count={ticketActivityOverdues.length}>
                               Лог изменений
                             </Badge>}>
                      <Activities
                        ticketActivities={ticketActivities}
                        ticketActivityOverdues={ticketActivityOverdues}
                        isActivitiesLoading={isActivitiesLoading}
                      />
                    </TabPane>
                  )}
                  {
                    <TabPane tab="Задачи" key="3">
                      <Tasks isLoading={isTasksLoading} tasks={tasks} />
                    </TabPane>
                  }
                </Tabs>
              </Card>
            </Row>
          </Col>
        </Card>
      </Form>
    );
  }
}

FormInfo.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  ticket: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(FormInfo);
