// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Row, Col, Card, Timeline, Icon, Button, Divider} from "antd";

import moment from "moment";
import "moment/locale/ru";

class Activities extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      ticketActivities,
      ticketActivityOverdues,
      isActivitiesLoading,
    } = this.props;

    const overduedTicketActivityIds = ticketActivityOverdues
      .map(it => it.ticket_activity_id)

    return (
      <div>
        <Card loading={isActivitiesLoading} size="small" title="Активность">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Timeline>
                {ticketActivities.map(item => {
                  return (
                    <Timeline.Item
                      color={item.event_type > 2 ? "green" : "blue"}
                      key={item.id}
                      dot={
                        <Icon
                          type="history"
                          style={{ fontSize: "20px" }}
                        />
                      }
                    >
                      <p style={{ opacity: "0.7" }}>
                        {moment(item.created_at).format("LLL")} -{" "}
                        {item.user ? item.user.name : item.member ? item.member.name : null}
                      </p>
                      <p>{`${item.description}`} {item.to_value ? `(${item.to_value})` : null} {item.from_value ? `(${item.from_value})` : null}</p>
                      {
                        overduedTicketActivityIds.includes(item.id) ?
                          <Divider className={'ticket-overdue-divider'}
                                   orientation={'right'}>
                            🙁
                          </Divider> :
                          null
                      }
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

Activities.propTypes = {
  ticketActivities: PropTypes.arrayOf(PropTypes.object)
};
export default Activities;
